import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import ServicesItem from './ServicesItem'
import DartHeading from './icons/DartHeading'

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 16, // Half the value of the grid spacing
    backgroundColor: theme.palette.grey[200],
  },
  container: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  iconText: {
    width: 400,
    "& #line": {
      fill: "transparent",
    },
    "& #mainText": {
      "alignment-baseline": "middle",
      fontFamily: theme.typography.fontFamilySecond,
      fill: theme.palette.primary.light,
      fontSize: 64,
      fontWeight: 700,
      "text-anchor": "middle",
    },
    "& #dart": {
      fill: theme.palette.common.black,
    },
    "& #dart_2": {
      fill: theme.palette.primary.dark,
    }
  }
});

class Services extends React.Component {
  render() {
    const { children, classes, id, data } = this.props
    const { services: { items } } = data
    return (
      <div className={classes.root}>

        <Grid id={id || "services-grid"} container justify="center" spacing={32}>
          <Grid item xs={12}>
            <Grid container justify="center">
              <DartHeading viewBox="0 250 800 300" className={classes.iconText} iconText="Services" />
            </Grid>
          </Grid>

          <Grid item xs={12} lg={10}>
            {children}
          </Grid>
          <Grid item xs={12} lg={10}>
            <Grid container className={classes.container} justify="center" spacing={24}>
              {items && items.filter(t => !t.hidden).map((item, i) => {
                return (<ServicesItem key={i} data={item} />)
              })
              }
            </Grid>
          </Grid>
        </Grid>

      </div>
    )
  }
}

Services.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
}

export default withStyles(styles)(Services)
