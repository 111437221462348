import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Markdown from 'react-markdown'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import LogoIcon from './icons/LogoIcon'

const styles = theme => ({
  root: {
    height: 400,
    borderRight: "1rem",
    borderLeft: `solid ${theme.spacing.unit * 0.5}px`,
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    maxWidth: 500,
  },
  paper: {
    position: "relative",
    padding: theme.spacing.unit,
    height: 400 - (theme.spacing.unit * 4),
    minWidth: 360
  },
  button: {
    position: "absolute",
    bottom: theme.spacing.unit,
  },
  icon: {
    backgroundColor: theme.palette.hero.light,
    width: theme.spacing.unit * 10,
    borderRadius: "50%",
    margin: `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit * 3}px ${theme.spacing.unit}px`,
    "& #top, #bottom": {
      fill: theme.palette.primary.dark
    },
    "& #dart": {
      fill: theme.palette.primary.main,
    },
  },
});

class ServicesItem extends React.Component {
  render() {
    const { data, classes } = this.props
    return (
      <Grid item className={classes.root} >
        <Paper className={classes.paper} elevation={0}>
          <LogoIcon className={classes.icon} />
          <Typography variant="h4">{data.label}</Typography>
          <Typography variant="body1" color="inherit" component={Markdown} source={data.description} gutterBottom />
          <Button variant="contained" className={classes.button} color="primary" component={Link} to={data.route}>{data.buttonText || "Read About"}</Button>
        </Paper>
      </Grid>
      )
  }
}

ServicesItem.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withStyles(styles)(ServicesItem)
