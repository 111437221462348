import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

import { withStyles } from '@material-ui/core/styles';
import Services from '../components/ServicesQuery'
import IntroSection from '../components/IntroSectionQuery'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

class ServicesPage extends React.Component {
  render() {
    // store up the array of images fluid value from imageSharp
    const { classes, data } = this.props
    return (
      <Layout
        className={classes.root}
        location={this.props.location}
        pagedata={{ title: data.servicesHeader.title, description: data.servicesHeader.introduction }}
      >
      <Services>
        <IntroSection />
      </Services>
      </Layout>
    )
  }
}

export default withStyles(styles)(ServicesPage)

export const servicesHeader = graphql`
  query { 
    servicesHeader: services {
      title
      introduction
    }
  }
`
