import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Services from "./Services"

export default props => (
  <StaticQuery
    query={graphql`
      query {
        services {
          title
          introduction
          items {
            label
            description
            route
            buttonText
            hidden
          }
        }
      }
    `}
    render={data => <Services data={data} {...props} />}
  />
)
